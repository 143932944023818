@media (min-width: 360px) {
    .pose-face-image {
        width: 80vw;
        margin: 10px auto 50px;
    }
    .pose-face-image .pose-face-image-header {
        width: 100%;
        height: 40px;
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
    }
    .pose-face-image .pose-face-image-header > button {
        width: 40px;
        height: 40px;
        margin-right: 20px;
    }
    
    .pose-face-image .pose-face-image-box {
        display: flex;
    }
    
    .pose-face-image .prompt {
        width: 27vw;
        min-height: 400px;
        border-radius: 16px;
        border: 1px solid rgba(218, 220, 224, 1);
        box-shadow: 0 4px 4px 0 rgba(0,0,0,.25);
        margin-right: 40px;
        position: relative;
        align-self: flex-start;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .pose-face-image .title {
        width: 100%;
        height: 50px;
    }
    .pose-face-image .up-down-title {
        height: 50px;
    }
    .pose-face-image .title .up-icon {
        color: rgba(0, 0, 0, 1) !important;
    }
    .pose-face-image .title span:nth-of-type(1) {
        font-size: 18px;
        font-weight: 500;
        line-height: 50px;
    }
    .pose-face-image .title span:nth-of-type(2) {
        font-size: 20px;
        color: rgba(128, 128, 128, .5);
        margin-left: 10px;
    }
    .pose-face-image .prompt .but {
        width: 93%;
        height: 50px;
        position: absolute;
        bottom: 20px;
        background-color: rgba(0, 0, 0, 1);
    }
    .pose-face-image .prompt .but:disabled {
        background-color: rgba(255, 255, 255, 1);
    }
    .pose-face-image .prompt .but:disabled:hover {
        background-color: rgba(255, 255, 255, 1);
    }
    .pose-face-image .prompt .but:hover {
        background-color: rgba(0, 0, 0, .8);
    }
    .pose-face-image .output-image {
        flex: 1;
        position: relative;
        align-self: flex-start;
    }
    .pose-face-image .output-image .output-image-bfc {
        width: 100%;
        height: 752px;
        border-radius: 16px;
        padding: 16px;
        border: 1px solid rgba(218, 220, 224, 1);
        box-shadow: 0 4px 4px 0 rgba(0,0,0,.25);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        align-self: flex-start;
    }
    .pose-face-image .output-image .img {
        max-width: calc(50vw - 32px);
        max-height: 720px;
        width: auto;
        height: auto;
    }
    .pose-face-image .output-image .loading-box {
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .pose-face-image .output-image .loading {
        width: 32px;
        height: 32px;
    }
    .pose-face-image .output-image .text {
        height: 32px;
        line-height: 32px;
        margin-top: 20px;
        color: rgba(128, 128, 128, .5);
    }
    .pose-face-image .output-image .but {
        position: absolute;
        bottom: -50px;
        right: 0;
    }
    
    .pose-face-image .form {
        width: 93%;
        margin: 0 10px 100px 10px;
        overflow: hidden;
    }
    .pose-face-image .form .upload-source {
        width: 100%;
    }

    .pose-face-image .prompt .style {
        width: 100%;
        height: 46px;
    }
    .pose-face-image .prompt .style .ant-select-selector {
        border-radius: 16px;
        padding-left: 20px;
    }

    .pose-face-image .pose-face-image-box .ratio {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .pose-face-image .ratio label {
        width: 110px;
        border-radius: 16px;
        margin-bottom: 20px;
        border: 1px solid currentColor;
        text-align: center;
    }
    .pose-face-image .ratio label::before {
        content: none;
    }
    
    .pose-face-image .ant-upload {
        border: none !important;
    }
    .pose-face-image .ant-btn[disabled] {
        background-color: white
    }
    .pose-face-image .form .hint {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 20px;
    }
    .pose-face-image .ant-upload-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .pose-face-image .ant-upload-list .ant-upload-list-item-actions {
        display: flex;
        justify-content: space-around;
        flex-wrap: nowrap;
    }
    .pose-face-image .ant-upload-list .ant-tooltip {
        display: none;
    }
    .pose-face-image .ant-upload-list .ant-upload-list-item {
        border-color: rgba(217, 217, 217, 1) !important;
        color: rgba(217, 217, 217, 1) !important;
    }
    .pose-face-image .ant-upload-list-text {
        display: none;
    }
    .pose-face-image .ant-upload-list-item-container {
        margin: 8px !important;
    }
    .pose-face-image .ant-upload-select {
        margin: 8px;
    }
    .pose-face-image .ant-upload-select button span {
        color: rgba(128, 128, 128, 1);
        font-size: 30px;
    }
    .pose-face-image .ant-upload-select button div {
        font-size: 14px;
    }
    .preview .ant-modal-content {
        padding: 24px;
    }
    .preview .ant-modal-content .react-resizable-handle {
        position: absolute;
        bottom: -20px;
        right: -20px;
    }
}